<template>
    <div class="expertIntroduction">
        <div class="banner">
            <img src="../assets/images/expert/banner.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/">专家介绍</a>
            </div>
        </div>
        <div class="expert">
            <div class="expert-content">
                <div class="contnet">
                    <div class="eitem-box" v-for="(item,index) in expertList" :key="index" :limit="4" @click="goDetail(item)">
                        <img :src="imgurl+item.img" alt="" width="100%">
                        <div class="overlay" >
                            <p class="oname">{{ item.xm }}</p>
                            <p class="open">{{ item.zw }}</p>
                            <p class="odesc">{{ item.jj }}</p>
                            <p class="more">查看更多>></p>
                            </div>
                            <div class="non-hover-text" >
                            <div>{{ item.xm }}</div>
                            <div class="position">{{ item.zw }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages">
            <el-pagination
                :page-size="6"
                background
                layout="prev, pager, next"
                :total="total"
                @current-change="changesome"
                >
            </el-pagination>
       </div>

    </div>
</template>

<script>
	import request from "../utils/request.js";
		  import { apiUrl } from '@/config'
	import Swiper from "swiper";
	import { setTimeout } from "timers";
export default {
    data(){
        return {
			queryId:597,
			total:0,
			pageNum:1,
            current:1,
			imgurl:apiUrl,
            expertList:[
                // {
                //     name:"陈某某",
                //     position:"某公司副总",
                //     description:'某公司副总某公司副总某公司副总某公司副总某公司副总某公司副总',
                //     img:require('@/assets/images/expert/zjjs1.png')
                // }
          

            ]
        }
    },
    methods:{
        changesome(current){
			var that = this;
			that.pageNum = current;
            this.getZjjs();
        },
        goDetail(item){
           
			  this.$router.push({path:"expertdetails/"+this.queryId+"/"+item.id,})
        },
		/**专家介绍列表**/
		getZjjs(){
		var that=this
		var parm={
   "groupval": "",
   "pageSize": 10,
   "pageNum": 1,
   "orderByColumn": "id",
   "isAsc": "desc",
   "formId": 826,
   "xssx": "xm,zw",
   "queryId": "597",
   "queryStr": "",
   "isId": 0
}
				
		request.post('/spms/previewQuery/list',parm).then(res =>{
			if(res.code==200){
				const {data}=res
				data.rows.forEach(item =>{
					if(item.txDepotCompressUrl && item.txDepotCompressUrl.length>0){
						item['img']=item.txDepotCompressUrl[0]
					}
					
				})
				that.expertList=data.rows;
				that.total=data.total
			}
		})
	}
    },
    mounted(){ 
		
		this.getZjjs()
	}
}
</script>
<style lang="scss" scoped>
.expertIntroduction{
    box-sizing: border-box;
    .banner{
        width: 100%;
        height: 300px;
        .bimg{
            width: 100%;
            height:100%;
        }
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        margin-bottom: 30px;
        .orientation-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 60px;
            color:#666666
        }
    }
    .expert{
        .expert-content{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            .contnet{
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .eitem-box{
                    width: 280px;
                    height: 350px;
                    position: relative;
                    display: inline-block;
                    overflow: hidden;
                    margin-bottom: 20px;
                    cursor: pointer;
                    &:hover{
                        .overlay{
                            display: block;
                        }
                        .non-hover-text{
                            display: none;
                        }
                    }
                    .overlay{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.7);
                        color:white;
                        font-size: 18px;
                        display: none;
                        padding: 10px;
                        .odesc{
                            height: 200px;
                            overflow: hidden;
                        }
                    }
                    .overlay p{
                        text-align: left;
                    }
                    .non-hover-text{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        color: white;
                        padding: 15px;
                        font-size: 18px;
                    }
                    div{
                        margin-top: 3px;
                    }

                }


            }
        }
    }
    // 分页
    .pages{
        margin:40px 0;
        display:flex;
        justify-content: center;
    }
}
// @media (max-width: 750.01px){
//     .expert-content{
//         width: 100%  !important;
//         font-size: 10px;
//         .content{
//             width: 100% !important;
//             flex-direction: column;
//             .item-box{
//                 width: 100% !important;
//             }
            
//         }
//     }

// }
</style>
